.menuOpened {
  transition: height 0.3s;
  transition-timing-function: ease-in;
  height: 100%;
}

.menuClosed {
  transition: height 0.3s;
  transition-timing-function: ease-out;
  height: 0px;
}
