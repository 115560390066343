.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*   Custom CSS   */

@font-face {
  font-family: HelveticaBold;
  src: url("./assets/fonts/HelveticaLTStd-Bold.otf");
}

@font-face {
  font-family: HelveticaRoman;
  src: url("./assets/fonts/Helvetica\ LT\ Std\ Roman.otf");
}

@font-face {
  font-family: HelveticaLight;
  src: url("./assets/fonts/HelveticaLTStd-Light.otf");
}

/*  Custom Colors    */

:root {
  --defautColor: #0295ff;
}

*:focus {
  outline: none;
}

/* FAQ item expanded border  */
.szh-accordion__item--expanded {
  border: 1px solid var(--defautColor) !important;
}

.video-player.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: black; /* Change this to your preferred background color */
}
