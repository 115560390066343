.menuOpened {
  transition: width 0.3s;
  transition-timing-function: ease-in;
  width: 100%;
  /* transform-origin: top right; */
}

.menuClosed {
  transition: width 0.3s;
  transition-timing-function: ease-out;
  width: 0px;
  /* transform-origin: top right; */
}
