.item {
  border: 1px solid gray;
  border-radius: 5px;
  margin-bottom: 25px;
}

.itemBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 14px;
  padding-right: 14px;
  font-size: 1.275em;
  font-family: HelveticaRoman;
  text-align: left;
  color: #393939;
  background-color: transparent;
  border: none;
}

.itemBtn:hover {
  background-color: #f3f3f3;
}

.itemBtnExpanded {
  /* background-color: #e7e7e7; */

  color: var(--defautColor);
  border-bottom: 1px solid var(--defautColor);
}

.itemContent {
  transition: height 0.1s ease;
  font-size: 1.1em;
  font-family: HelveticaRoman;
  color: gray;
}

.itemPanel {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 14px;
  padding-right: 14px;
}

.chevron {
  margin-left: auto;
  transition: transform 0.2s ease-in-out;
}
.itemBtnExpanded .chevron {
  transform: rotate(180deg);
}
